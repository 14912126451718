@font-face {
    font-family: 'Poppins-Bold';
    src: url(./fonts/Poppins-Bold.ttf);
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Regular';
    src: url(./fonts/Poppins-Regular.ttf);
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-SemiBold';
    src: url(./fonts/Poppins-SemiBold.ttf);
    font-style: normal;
}

@media print {
  @page {
      margin: 0;
  }
}

p, h1, h2, h3, h4 {
  font-family: 'Poppins-Regular', Helvetica, Arial, Verdana, Tahoma, sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

html, body, #root, .App {
  min-height: 100vh;
  width: 100vw;
}

body {
  background-color: #f7f8fa;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f7f8fa;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #f7f8fa;
}

::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(58, 57, 57, 0.3);
  background-color: #512C85;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
}

.centered {
  display: flex;
  justify-content: center;
}

.spaced {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Modal */
.modal__title {
  font-family: 'Poppins-Bold';
  font-size: 20px;
  line-height: 30px;
  color: #32C585;
  margin-bottom: 40px;
}

.modal__row {
  display: flex;
  margin-bottom: 24px;
}

.modal__row > div.col-half {
  width: 50%;
}
